<template>
  <div>
    <div>
      <el-button
        :type="displayEnded ? '' : 'primary'"
        @click="() => displayEnded = false"
      >
        {{ $t("userProfile.teacher.currentCourses") }}
      </el-button>
      <el-button
        :type="displayEnded ? 'primary' : ''"
        @click="() => displayEnded = true"
      >
        {{ $t("userProfile.teacher.pastCourses") }}
      </el-button>
    </div>
    <br />
    <ul style="margin-bottom: 0">
      <ol
        v-for="(item, index) in displayEnded ? endedClasses : currentClasses"
        :key="index"
        style="padding:0"
      >
        <div>
          <a
            :href="`/session-classes/${item.id}`"
            v-if="item.type === 'default'"
            target="_blank"
          >
            {{ index + 1 }}. {{ item.title }} ({{ `${item.students.length} ${$t("userProfile.teacher.student")}` }})
          </a>
          <a
            :href="`/private/${isCounseling(item.course_session_id) ? 'counseling' : 'private-lessons'}/${item.id}/detail`"
            v-if="item.type === 'custom'"
            target="_blank"
          >
            {{ index + 1 }}. {{ item.title }} -
            {{ helper.displayMultipleItems(item.students.map(({ name }) => name)) }} (With {{ item.teacher ? item.teacher.name : '' }})
          </a>
        </div>
      </ol>
    </ul>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";

export default {
  props: {
    classes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      displayEnded: false
    };
  },
  computed: {
    helper() {
      return helper;
    },
    currentClasses() {
      return this.classes.filter(({ is_ended }) => !is_ended);
    },
    endedClasses() {
      return this.classes.filter(({ is_ended }) => is_ended);
    }
  },
  watch: {},

  mounted() {},

  methods: {
    isCounseling(courseSessionId) {
      const COUNSELING_IDS = [4, 6];
      return COUNSELING_IDS.includes(courseSessionId);
    }
  }
};
</script>

<style scoped>
.ended > a {
  text-decoration: line-through;
}
</style>
