<template>
  <div class="score_border">
    <div class="score_border_head">
      <div>
        <h4>
          MyBest
          <sup class="tm">TM</sup> Scores
        </h4>
        <h6>Your highest section scores from all valid test dates.</h6>
      </div>
    </div>
    <div class="row" style="padding: 0 20px">
      <div class="col-md-4 col-lg-2">
        <h5 class="text-center">Sum of Highest Section Scores</h5>
        <div>
          <div>
            <div class="text-center progress_total">{{ total }}</div>
          </div>
          <h5 class="text-center">out of 120</h5>
        </div>
      </div>
      <div class="row col-md-8 col-lg-10">
        <!-- Reading + Listening -->
        <div class="col-xl-6">
          <div class="row score_section rmax_score_progress">
            <div class="col-sm-5" style="padding-bottom: 9.5px;">
              <h5>
                Reading:
                <a href>{{ maxScore.reading }}</a>
              </h5>
              <h5 class="date">{{ getDate(maxScore.readingDate) }}</h5>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{
                    'margin-left': marginReading
                  }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
          <div class="row score_section lmax_score_progress">
            <div class="col-sm-5" style="padding-bottom: 9.5px;">
              <h5>
                Listening:
                <a href>{{ maxScore.listening }}</a>
              </h5>
              <h5 class="date">{{ getDate(maxScore.listeningDate) }}</h5>
            </div>
            <div class="col-sm-7">
              <div style="height: 10px">
                <div
                  class="corner"
                  :style="{
                    'margin-left': marginListening
                  }"
                ></div>
              </div>
              <div style="height: 47px;line-height: 47px">
                <b style="display:inline-block;width: 10px;">0</b>
                <div class="score_progress"></div>
                <b>30</b>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Reading + Listening -->

        <!-- Speaking + Writing -->
        <div class="col-xl-6">
          <div class="score_section smax_score_progress">
            <div v-if="true" class="row">
              <div class="col-sm-5">
                <h5>
                  Speaking:
                  <a href>{{ maxScore.speaking }}</a>
                </h5>
                <h5 class="date">{{ getDate(maxScore.speakingDate) }}</h5>
              </div>
              <div class="col-sm-7">
                <div style="height: 10px">
                  <div
                    class="corner"
                    :style="{
                      'margin-left': marginSpeaking
                    }"
                  ></div>
                </div>
                <div style="height: 47px;line-height: 47px">
                  <b style="display:inline-block;width: 10px;">0</b>
                  <div class="score_progress"></div>
                  <b>30</b>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-sm-5">
                <h4>Speaking: -</h4>
              </div>
            </div>
          </div>

          <div class="score_section wmax_score_progress">
            <div v-if="true" class="row">
              <div class="col-sm-5">
                <h5>
                  Writing:
                  <a href>{{ maxScore.writing }}</a>
                  <h5 class="date">{{ getDate(maxScore.writingDate) }}</h5>
                </h5>
              </div>
              <div class="col-sm-7">
                <div style="height: 10px">
                  <div
                    class="corner"
                    :style="{
                      'margin-left': marginWriting
                    }"
                  ></div>
                </div>
                <div style="height: 47px;line-height: 47px">
                  <b style="display:inline-block;width: 10px;">0</b>
                  <div class="score_progress"></div>
                  <b>30</b>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-sm-5">
                <h5>Writing: -</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Speaking + Writing -->
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import "@/views/toefl/transcript/style/reportcard.css";

export default {
  components: {},

  mixins: [],

  props: ["maxScore"],
  data() {
    return {
      marginReading: "",
      marginListening: "",
      marginSpeaking: "",
      marginWriting: ""
    };
  },
  computed: {
    total() {
      let total = 0;
      total =
        this.maxScore.reading +
        this.maxScore.listening +
        this.maxScore.speaking +
        this.maxScore.writing;
      return total;
    }
  },
  watch: {},
  created() {
    this.marginReading = (this.maxScore.reading / 30) * 215 + 8 + "px";
    this.marginListening = (this.maxScore.listening / 30) * 215 + 8 + "px";
    this.marginSpeaking = (this.maxScore.speaking / 30) * 215 + 8 + "px";
    this.marginWriting = (this.maxScore.writing / 30) * 215 + 8 + "px";
  },

  mounted() {},

  methods: {
    getLeft(score) {
      let progress_width = document.getElementsByClassName("score_progress")[0];
      let width = 0;
      if (progress_width != undefined) {
        width = progress_width.offsetWidth;
      }
      return (score / 30) * width + 8 + "px";
    },
    getDate(date) {
      return date.split(" ")[0];
    }
  }
};
</script>

<style scoped>
.score_progress {
  margin: 0 5px;
}
.score_border_head h4,
.score_border_head h6 {
  color: #fff;
}
h5 {
  font-size: 18px;
  line-height: 150%;
  margin: 3px 0;
  color: black;
}
.tm {
  font-size: 14px;
}

.date {
  font-size: 14px;
}
</style>
