var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score_border"},[_c('div',{staticClass:"score_border_head"},[_c('div',[_c('h4',[_vm._v(" Super Scores "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"What is super score?","placement":"top"}},[_c('small',[_c('a',{attrs:{"href":"https://blog.ivy-way.com/what-is-super-score","target":"_blank"}},[_c('i',{staticClass:"fa fa-question-circle question-icon",attrs:{"aria-hidden":"true"}})])])])],1),_c('h6',[_vm._v(" Your highest section scores. ")])])]),_c('div',{staticClass:"row",staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"col-md-4"},[_c('h5',{staticClass:"text-center"},[_vm._v("Sum of Highest Section Scores")]),_c('div',[_c('div',[_c('div',{staticClass:"text-center progress_total"},[_vm._v(" "+_vm._s(_vm.bestScore.total_score)+" ")])]),_c('h5',{staticClass:"text-center"},[_vm._v("out of 36")])])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row score_section"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" English: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.bestScore.section_english_score))])])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                'margin-left': _vm.getLeft(_vm.bestScore.section_english_score)
              })})]),_vm._m(0)])]),_c('div',{staticClass:"row score_section"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" Math: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.bestScore.section_math_score))])])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                'margin-left': _vm.getLeft(_vm.bestScore.section_math_score)
              })})]),_vm._m(1)])]),_c('div',{staticClass:"row score_section"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" Reading: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.bestScore.section_reading_score))])])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                'margin-left': _vm.getLeft(_vm.bestScore.section_reading_score)
              })})]),_vm._m(2)])]),_c('div',{staticClass:"row score_section"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" Science: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.bestScore.section_science_score))])])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                'margin-left': _vm.getLeft(_vm.bestScore.section_science_score)
              })})]),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("36")])])
}]

export { render, staticRenderFns }