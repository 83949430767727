var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score_border"},[_vm._m(0),_c('div',{staticClass:"row",staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"col-md-4 col-lg-2"},[_c('h5',{staticClass:"text-center"},[_vm._v("Sum of Highest Section Scores")]),_c('div',[_c('div',[_c('div',{staticClass:"text-center progress_total"},[_vm._v(_vm._s(_vm.total))])]),_c('h5',{staticClass:"text-center"},[_vm._v("out of 120")])])]),_c('div',{staticClass:"row col-md-8 col-lg-10"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"row score_section rmax_score_progress"},[_c('div',{staticClass:"col-sm-5",staticStyle:{"padding-bottom":"9.5px"}},[_c('h5',[_vm._v(" Reading: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.maxScore.reading))])]),_c('h5',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDate(_vm.maxScore.readingDate)))])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                  'margin-left': _vm.marginReading
                })})]),_vm._m(1)])]),_c('div',{staticClass:"row score_section lmax_score_progress"},[_c('div',{staticClass:"col-sm-5",staticStyle:{"padding-bottom":"9.5px"}},[_c('h5',[_vm._v(" Listening: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.maxScore.listening))])]),_c('h5',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDate(_vm.maxScore.listeningDate)))])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                  'margin-left': _vm.marginListening
                })})]),_vm._m(2)])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"score_section smax_score_progress"},[(true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" Speaking: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.maxScore.speaking))])]),_c('h5',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDate(_vm.maxScore.speakingDate)))])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                    'margin-left': _vm.marginSpeaking
                  })})]),_vm._m(3)])]):_c('div',{staticClass:"row"},[_vm._m(4)])]),_c('div',{staticClass:"score_section wmax_score_progress"},[(true)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v(" Writing: "),_c('a',{attrs:{"href":""}},[_vm._v(_vm._s(_vm.maxScore.writing))]),_c('h5',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDate(_vm.maxScore.writingDate)))])])]),_c('div',{staticClass:"col-sm-7"},[_c('div',{staticStyle:{"height":"10px"}},[_c('div',{staticClass:"corner",style:({
                    'margin-left': _vm.marginWriting
                  })})]),_vm._m(5)])]):_c('div',{staticClass:"row"},[_vm._m(6)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score_border_head"},[_c('div',[_c('h4',[_vm._v(" MyBest "),_c('sup',{staticClass:"tm"},[_vm._v("TM")]),_vm._v(" Scores ")]),_c('h6',[_vm._v("Your highest section scores from all valid test dates.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-5"},[_c('h4',[_vm._v("Speaking: -")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"47px","line-height":"47px"}},[_c('b',{staticStyle:{"display":"inline-block","width":"10px"}},[_vm._v("0")]),_c('div',{staticClass:"score_progress"}),_c('b',[_vm._v("30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-5"},[_c('h5',[_vm._v("Writing: -")])])
}]

export { render, staticRenderFns }