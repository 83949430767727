export default class {
  constructor(props) {
    const {
      is_real_test = 1,
      listening_score = 0,
      reading_score = 0,
      score = 0,
      speaking_score = 0,
      taken_on = null,
      user_id = null,
      writing_score = 0,
      id = null
    } = props || {};

    this.id = id;
    this.listening_score = listening_score;
    this.speaking_score = speaking_score;
    this.is_real_test = is_real_test;
    this.reading_score = reading_score;
    this.score = score;
    this.taken_on = taken_on;
    this.user_id = user_id;
    this.writing_score = writing_score;
  }
}
