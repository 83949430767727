<template>
  <div>
    <el-button
      size="mini"
      type="primary"
      @click="() => setEditTagsDialogVisible(true)"
    >
      編輯標籤
    </el-button>
    <el-dialog
      title="編輯標籤"
      :visible.sync="editTagsDialogVisible"
      :before-close="() => setEditTagsDialogVisible(false)"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-select
        v-model="newUserTagIds"
        multiple
        placeholder="Select"
        style="width: 100%"
      >
        <el-option
          v-for="tag in tags"
          :key="tag.id"
          :label="`[${tag.type}] ${tag.name[lang]}`"
          :value="tag.id"
        />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="() => setEditTagsDialogVisible(false)">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button type="primary" @click="saveUserTags">
          {{ $t("button.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tagApi from "@/apis/tag";
import userApi from "@/apis/user";
import roleMixin from "@/mixins/role";

export default {
  props: {
    userId: {
      type: Number,
      default: null
    },
    userRoles: {
      type: Array,
      default: () => []
    },
    userTags: {
      type: Array,
      default: () => []
    }
  },
  mixins: [roleMixin],
  data() {
    return {
      editTagsDialogVisible: false,
      newUserTagIds: [],
      tags: []
    };
  },
  async created() {
    await this.fetchTags();
  },
  watch: {
    userRoles(){
      this.fetchTags();
    }
  },
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    }
  },
  methods: {
    async fetchTags() {
      let tagResponse = await tagApi.fetchTags();
      this.tags = (tagResponse.tags || []).filter(
        ({ type }) => this.userRoles.includes(type) || type === "user"
      );
      this.sortTagByType();
    },
    sortTagByType() {
      this.tags.sort((a, b) => (a.type > b.type ? -1 : 1));
    },
    setEditTagsDialogVisible(visibleStatus) {
      if (visibleStatus) {
        this.newUserTagIds = this.userTags.map(({ id }) => id);
      } else {
        this.newUserTagIds = [];
      }
      this.editTagsDialogVisible = visibleStatus;
    },
    async saveUserTags() {
      const originalTags = this.userTags.map(({ id }) => id);
      const attachTags = this.newUserTagIds.filter(
        tagId => !originalTags.includes(tagId)
      );
      await userApi.attachTagsToUser(this.userId, attachTags);
      const detachTags = originalTags.filter(
        tagId => !this.newUserTagIds.includes(tagId));
      await userApi.detachTagsToUser(this.userId, detachTags);
      this.setEditTagsDialogVisible(false);
      this.$emit("onSaved");
    }
  }
};
</script>
