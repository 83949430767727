export default class {
  constructor(props) {
    const {
      english_score = 0,
      is_real_test = 1,
      math_score = 0,
      reading_score = 0,
      science_score = 0,
      score = 0,
      taken_on = null,
      user_id = null,
      id = null,
      writing_score = 0
    } = props || {};

    this.id = id;
    this.english_score = english_score;
    this.is_real_test = is_real_test;
    this.math_score = math_score;
    this.reading_score = reading_score;
    this.science_score = science_score;
    this.score = score;
    this.taken_on = taken_on;
    this.user_id = user_id;
    this.writing_score = writing_score;
  }
}
