var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',[_vm._v(" "+_vm._s(_vm.$t("userProfile.parent.linkedStudents"))+" ")]),_c('el-row',[_c('el-table',{attrs:{"data":_vm.attachAddButton(_vm.students)}},[_c('el-table-column',{attrs:{"label":_vm.$t('column.student')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type === 'addItem')?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.addStudent}},[_vm._v(" "+_vm._s(_vm.$t("userProfile.parent.addStudent"))+" ")]):_c('div',[(scope.row.edit)?_c('StudentSelector',{attrs:{"value":scope.row.newStudentId || scope.row.student_user_id,"students":_vm.allStudents},on:{"change":({ user_id }) => {
                  _vm.setStudent(
                    scope.$index,
                    scope.row.student_user_id,
                    user_id
                  );
                }}}):_c('span',[(_vm.isRoleAdmin())?_c('router-link',{attrs:{"to":{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.student_user_id }
                }}},[_vm._v(" "+_vm._s(`${scope.row.first_name} ${scope.row.last_name}`)+" ")]):_c('span',[_c('router-link',{attrs:{"to":{
                    name: 'ProfileForAdmin',
                    params: { id: scope.row.student_user_id }
                  }}},[_vm._v(" "+_vm._s(`${scope.row.first_name} ${scope.row.last_name}`)+" ")])],1)],1)],1)]}}])}),(this.isRoleAdmin())?_c('el-table-column',{attrs:{"label":_vm.$t('column.action')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type !== 'addItem')?_c('div',[(scope.row.edit)?_c('div',[_c('el-tooltip',{attrs:{"value":true,"content":_vm.$t('message.remeberSave'),"placement":"top","popper-class":"tooltipColor"}},[_c('span',{key:"check",staticClass:"action-icon",on:{"click":function($event){return _vm.connectStudent(scope.row)}}},[_c('i',{staticClass:"fas fa-check"})])]),_c('span',{key:"times",staticClass:"warning-icon",on:{"click":function($event){return _vm.closeEditMode(scope.row, scope.$index)}}},[_c('i',{staticClass:"fas fa-times"})])],1):_c('div',[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.openEditMode(scope.row.student_user_id)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{staticClass:"warning-icon",on:{"click":function($event){return _vm.disconnectStudent(scope.row.student_user_id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])]):_vm._e()]}}],null,false,169321391)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }