<template>
  <div>
    <template v-if="this.id">
      <el-button type="primary" @click="showWindow">
        <i class="fas fa-upload"></i>
        上傳老師合約
      </el-button>
      <el-dialog title="上傳合約" :visible.sync="window" width="50%">
        <el-form ref="ruleForm" :model="form" label-width="100px">
          <!-- <el-form-item label="合約" prop="contract_url">
            <div v-loading="fileLoading" element-loading-text="Uploading">
              <template v-if="form.contract_url">
                <a :href="form.contract_url" target="_blank" class="mr-3">
                  <i class="fas fa-eye action-icon" /> 查看合約內容
                </a>
                <el-button
                  @click="form.contract_url = null"
                  size="mini"
                  type="danger"
                >
                  重新上傳
                </el-button>
              </template>
              <el-upload
                v-else
                drag
                :action="postUrl"
                :headers="token"
                :data="{ type: 'contract' }"
                name="document"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    將文件拖到此處，或<em>點擊上傳</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="合約建立日期" prop="contract_created_at">
            <el-date-picker
              v-model="form.contract_created_at"
              type="date"
              placeholder="選擇日期"
              value-format="yyyy-MM-dd"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="合約文件" prop="signed_contract_url" required>
            <div v-loading="fileLoading2" element-loading-text="Uploading">
              <template v-if="form.signed_contract_url">
                <a
                  :href="form.signed_contract_url"
                  target="_blank"
                  class="mr-3"
                >
                  <i class="fas fa-eye action-icon" /> 查看合約文件
                </a>
                <el-button
                  @click="form.signed_contract_url = null"
                  size="mini"
                  type="danger"
                >
                  重新上傳
                </el-button>
              </template>
              <el-upload
                v-else
                drag
                :action="postUrl"
                :headers="token"
                :data="{ type: 'contract' }"
                name="document"
                :show-file-list="false"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload2"
              >
                <div>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    將文件拖到此處，或<em>點擊上傳</em>
                  </div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="合約內容" prop="title" required>
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="合約簽署日期" prop="contract_signed_at">
            <!-- <el-input v-model="form.contract_signed_at"></el-input> -->
            <el-date-picker
              :default-value="new Date()"
              v-model="form.contract_signed_at"
              placeholder="選擇日期"
              value-format="yyyy-MM-dd"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >儲存</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <hr />
    </template>
    <el-table :data="contracts" style="width: 100%">
      <el-table-column prop="title" label="合約內容">
        <template slot-scope="scope">
          <b> {{ scope.row.title }}</b>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="contract_url" label="合約">
        <template slot-scope="scope">
          <a
            v-if="scope.row.contract_url"
            :href="scope.row.contract_url"
            target="_blank"
            class="mr-3"
          >
            <i class="fas fa-eye action-icon" /> 查看合約內容
          </a>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column prop="contract_created_at" label="合約建立日期">
        <template slot-scope="scope">
          <span v-if="scope.row.contract_created_at">
            {{ instant.formatGoogleTime(scope.row.contract_created_at) }}
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column> -->
      <el-table-column prop="signed_contract_url" label="合約文件">
        <template slot-scope="scope">
          <a
            v-if="scope.row.signed_contract_url"
            :href="scope.row.signed_contract_url"
            target="_blank"
            class="mr-3"
          >
            <i class="fas fa-eye action-icon" /> 查看合約文件
          </a>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column prop="contract_signed_at" label="簽署日期">
        <template slot-scope="scope">
          <span v-if="scope.row.contract_signed_at">
            {{ instant.formatGoogleTime(scope.row.contract_signed_at) }}
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" v-if="this.id">
        <template slot-scope="scope">
          <el-tooltip class="mr-2" effect="dark" content="編輯" placement="top">
            <el-button
              type="primary"
              size="mini"
              plain
              @click="editContract(scope.row)"
            >
              <i class="fa fa-edit"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip class="mr-2" effect="dark" content="刪除" placement="top">
            <el-button
              type="danger"
              size="mini"
              plain
              @click="removeContracts(scope.row.id)"
            >
              <i class="fa fa-trash-alt" />
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import user from "@/apis/user";
import JwtService from "@/common/jwt.service";
import { helper, instant } from "@ivy-way/material";

export default {
  components: {},
  mixins: [],
  props: ["id"],
  data() {
    return {
      window: false,
      form: {
        title: null,
        teacher_user_id: null,
        session_class_id: null,
        contract_url: null,
        contract_created_at: null,
        signed_contract_url: null,
        contract_signed_at: new Date()
      },
      fileLoading: false,
      fileLoading2: false,
      contracts: []
    };
  },
  computed: {
    token() {
      return { Authorization: `Bearer ${JwtService.getToken()}` };
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    },
    instant() {
      return instant;
    },
    helper() {
      return helper;
    }
  },
  mounted() {
    this.getTeacherContracts();
  },
  methods: {
    async getTeacherContracts() {
      if (this.id) {
        const res = await user.getTeacherContracts({
          teacher_user_id: this.id
        });
        this.contracts = res.teacher_contracts;
      } else {
        const res = await user.getMineContracts();
        this.contracts = res.teacher_contracts;
      }
    },
    showWindow() {
      this.form = {
        title: null,
        teacher_user_id: null,
        session_class_id: null,
        contract_url: null,
        contract_created_at: null,
        signed_contract_url: null,
        contract_signed_at: new Date()
      };
      this.window = true;
    },
    handleAvatarSuccess(res) {
      this.form.contract_url = res.document.url;
      this.fileLoading = false;
    },
    handleAvatarSuccess2(res) {
      this.form.signed_contract_url = res.document.url;
      this.fileLoading2 = false;
    },
    beforeAvatarUpload() {
      this.fileLoading = true;
      return true;
    },
    beforeAvatarUpload2(file) {
      this.fileLoading2 = true;
      console.log(file);
      this.form.title = this.getFileName(file.name);
      return true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.form.teacher_user_id = this.id;
          if (this.form.id && this.form.id > -1) {
            await user.updateContracts(this.form.id, { ...this.form });
          } else {
            await user.createContracts({ ...this.form });
          }
          this.window = false;
          await this.resetForm(formName);
          await this.getTeacherContracts();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    editContract(data) {
      this.form = { ...data };
      this.window = true;
    },
    getFileName(name) {
      return name.substring(0, name.lastIndexOf("."));
    },
    removeContracts(id) {
      this.$confirm("確認刪除這條記錄？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          await user.removeContracts(id);
          this.$message({
            type: "success",
            message: "已刪除!"
          });
          await this.getTeacherContracts();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消刪除"
          });
        });
    }
  }
};
</script>

<style scoped></style>
