import testData from "@/data/tests.json";

export default {
  methods: {
    getCurrentTestArray(nameOfTests) {
      let tests = [];
      for (let i = 0; i < nameOfTests.length; i++) {
        const temp = testData.find(obj => {
          return obj.value === nameOfTests[i];
        });
        tests.push(temp);
      }
      return tests;
    },
    findTest(currSelected) {
      const res = this.tests.find(obj => {
        return obj.value === currSelected;
      });
      return res;
    },
    updateTest(value) {
      const objIndex = this.tests.findIndex(obj => obj.name === value.name);
      this.$set(this.tests, objIndex, value);
    },
    // ******sum of PSAT******
    sumPSAT(tempArray) {
      let total = 0;
      let errorInput = false;
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) {
          errorInput = true;
        }
        if (parseFloat(item) < 100 || parseFloat(item) > 800) {
          errorInput = true;
        }
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return 0;
      return errorInput ? 0 : total;
    },
    // ******sum of SAT student******
    sumSAT_student(tempArray) {
      let total = 0;
      let errorInput = false;
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) {
          errorInput = true;
        }
        if (parseFloat(item) < 100 || parseFloat(item) > 800) {
          errorInput = true;
        }
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return 0;
      return errorInput ? 0 : total;
    },
    // ******sum of SAT teacher******
    sumSAT_teacher(tempArray) {
      let total = 0;
      let errorInput = false;
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return (total += 0);
        if (!intPattern.test(item)) {
          errorInput = true;
        }
        if (parseFloat(item) < 200 || parseFloat(item) > 800) {
          errorInput = true;
        }
        total += parseInt(item);
      });
      if (total > 1600 || total < 400) return 0;
      return errorInput ? 0 : total;
    },
    // ******ave of ACT******
    aveACT(tempArray) {
      let total = 0;
      let ave = 0;
      let errorInput = false;
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return;
        if (!intPattern.test(item)) {
          errorInput = true;
        }
        if (parseInt(item) < 0 || parseInt(item) > 36) {
          errorInput = true;
        }
        total += parseInt(item);
      });
      ave = total / 4;
      ave = Math.round(ave * 10) / 10;
      if (ave > 36 || ave < 0) return 0;
      return errorInput ? 0 : ave;
    },
    // ******sum of TOEFL******
    sumTOEFL(tempArray) {
      let total = 0;
      let errorInput = false;
      const intPattern = /^\d*$/;
      tempArray.forEach(item => {
        if (item === "") return;
        if (!intPattern.test(item)) {
          errorInput = true;
        }
        if (parseInt(item) < 0 || parseInt(item) > 30) {
          errorInput = true;
        }
        total += parseInt(item);
      });
      return errorInput ? 0 : total;
    },
    // ******ave of IELTS******
    aveIELTS(tempArray) {
      let total = 0;
      let ave = 0;
      let errorInput = false;
      const numPattern = /^\d(\.5)?$/;
      tempArray.forEach(item => {
        item = parseFloat(item);
        item = item.toString();
        if (item === "") return;
        if (!numPattern.test(item)) {
          errorInput = true;
        }
        if (parseFloat(item) < 0 || parseFloat(item) > 9) {
          errorInput = true;
        }
        total += parseFloat(item);
      });
      if (errorInput) return 0;

      ave = total / 4;
      ave = ave * 1000;
      let integer = Math.floor(ave / 1000) * 1000;
      let decimal = ave % 1000;
      if (decimal >= 250 && decimal < 750) {
        decimal = 500;
        ave = (integer + decimal) / 1000;
      } else if (decimal < 250) {
        ave = integer / 1000;
      } else if (decimal >= 750) {
        ave = integer / 1000 + 1;
      }

      if (ave > 9 || ave < 0) return 0;
      return ave;
    }
  }
};
