<template>
  <div>
    <el-row>
      {{ $t("userProfile.parent.linkedStudents") }}
    </el-row>
    <el-row>
      <el-table :data="attachAddButton(students)">
        <el-table-column :label="$t('column.student')">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.type === 'addItem'"
              type="primary"
              size="mini"
              @click="addStudent"
            >
              {{ $t("userProfile.parent.addStudent") }}
            </el-button>
            <div v-else>
              <StudentSelector
                v-if="scope.row.edit"
                :value="scope.row.newStudentId || scope.row.student_user_id"
                :students="allStudents"
                @change="
                  ({ user_id }) => {
                    setStudent(
                      scope.$index,
                      scope.row.student_user_id,
                      user_id
                    );
                  }
                "
              />
              <span v-else>
                <router-link
                  v-if="isRoleAdmin()"
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: scope.row.student_user_id }
                  }"
                >
                  {{ `${scope.row.first_name} ${scope.row.last_name}` }}
                </router-link>
                <span v-else>
                  <router-link
                    :to="{
                      name: 'ProfileForAdmin',
                      params: { id: scope.row.student_user_id }
                    }"
                  >
                    {{ `${scope.row.first_name} ${scope.row.last_name}` }}
                  </router-link>
                </span>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="this.isRoleAdmin()" :label="$t('column.action')">
          <template slot-scope="scope">
            <div v-if="scope.row.type !== 'addItem'">
              <div v-if="scope.row.edit">
                <el-tooltip
                  :value="true"
                  :content="$t('message.remeberSave')"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span
                    key="check"
                    class="action-icon"
                    @click="connectStudent(scope.row)"
                  >
                    <i class="fas fa-check" />
                  </span>
                </el-tooltip>
                <span
                  key="times"
                  class="warning-icon"
                  @click="closeEditMode(scope.row, scope.$index)"
                >
                  <i class="fas fa-times" />
                </span>
              </div>
              <div v-else>
                <span
                  class="action-icon"
                  @click="openEditMode(scope.row.student_user_id)"
                >
                  <i class="fas fa-edit" />
                </span>
                <span
                  class="warning-icon"
                  @click="disconnectStudent(scope.row.student_user_id)"
                >
                  <i class="fas fa-trash-alt" />
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { StudentSelector } from "@/components/selector";
import roleMixin from "@/mixins/role";
import userApi from "@/apis/user";

export default {
  components: {
    StudentSelector
  },
  mixins: [roleMixin],
  props: ["id"],
  data() {
    return {
      students: [],
      allStudents: []
    };
  },
  created() {
    this.updateStudents();
    this.getAllStudents();
  },
  methods: {
    async getAllStudents(){
      const students = await userApi.searchStudents();
      this.allStudents = students;
    },
    attachAddButton(students) {
      if (!this.isRoleAdmin()) return students;
      return [...students, { type: "addItem" }];
    },
    updateStudents() {
      this.students = [
        ...this.$store.getters["profile/getUserDataByAdmin"].parent?.son_daughter || [],
        ...this.$store.getters["profile/getUserData"].parent?.son_daughter || []
      ];
    },
    openEditMode(studentId) {
      this.students = this.students.map(student => {
        if (student.student_user_id !== studentId) return student;
        return { ...student, edit: true };
      });
    },
    closeEditMode(targetStudent, targetIndex) {
      if (!targetStudent.first_name) {
        this.students = this.students.filter(
          (student, index) => index !== targetIndex
        );
      } else {
        this.students = this.students.map(student => {
          if (student.student_user_id !== targetStudent.student_user_id)
            return student;
          return {
            ...student,
            newStudentId: targetStudent.student_user_id,
            edit: false
          };
        });
      }
    },
    addStudent() {
      this.students = [...this.students, { student_user_id: null, edit: true }];
    },
    setStudent(targetIndex, originStudentId, newStudentId) {
      if (originStudentId === newStudentId) return;
      this.students = this.students.map((student, index) => {
        if (index !== targetIndex) return student;
        return {
          ...student,
          newStudentId
        };
      });
    },
    isExistStudent(newStudent) {
      const studentIndex = this.students
        .filter(student => !student.edit)
        .findIndex(
          student => student.student_user_id === newStudent.newStudentId
        );
      if (studentIndex === -1) return false;
      this.$message.error(this.$t("userProfile.parent.theStudentAlreadyExist"));
      return true;
    },
    async connectStudent(student) {
      if (this.isExistStudent(student)) return;
      if (
        student.newStudentId === undefined ||
        student.newStudentId === student.student_user_id
      ) {
        this.closeEditMode(student);
        return;
      }
      await this.disconnectStudent(student.student_user_id);
      await userApi.connectStudentAndParent(this.id, [student.newStudentId]);
      this.$emit("initialProfile");
    },
    async disconnectStudent(studentId) {
      await userApi.disconnectStudentAndParent(this.id, [studentId]);
      this.$emit("initialProfile");
    }
  }
};
</script>

<style scoped></style>
