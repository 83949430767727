<template>
  <div class="pl-2 mt-5">
    <h4 style="color:#42a16a">TOEFL Tests:</h4>

    <ToeflMyBestScore :maxScore="maxScore" />

    <el-table :data="toeflData" stripe>
      <el-table-column label="Test name" width="150%" align="center">
        <div
          slot-scope="scope"
          class="titleCell"
          @click="toTranscript(scope.row.user_exam_id)"
        >
          <span>{{ scope.row.title }}</span>
        </div>
      </el-table-column>
      <el-table-column label="Date taken" width="100%" align="center">
        <div slot-scope="scope">
          <span>{{ instant.formatGoogleTime(scope.row.date_taken) }}</span>
        </div>
      </el-table-column>
      <el-table-column label="Reading" width="100%" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub">
              <span>{{ scope.row.reading_score }}</span>
            </div>
            <div
              class="d-inline"
              style="font-size: 11px;"
              v-if="!scope.row.isInit"
            >
              <template v-if="scope.row.progressReading >= 0">
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressReading }}</i
                >
              </template>
              <template v-else>
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{ scope.row.progressReading | abs }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Listening" width="100%" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub">
              <span>{{ scope.row.listening_score }}</span>
            </div>
            <div
              class="d-inline"
              style="font-size: 11px;"
              v-if="!scope.row.isInit"
            >
              <template v-if="scope.row.progressListening >= 0">
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressListening }}</i
                >
              </template>
              <template v-else>
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{ scope.row.progressListening | abs }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Speaking" width="100%" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub">
              <span>{{ scope.row.speaking_score }}</span>
            </div>
            <div
              class="d-inline"
              style="font-size: 11px;"
              v-if="!scope.row.isInit"
            >
              <template v-if="scope.row.progressSpeaking >= 0">
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressSpeaking }}</i
                >
              </template>
              <template v-else>
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{ scope.row.progressSpeaking | abs }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Writing" width="100%" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub">
              <span>{{ scope.row.writing_score }}</span>
            </div>
            <div
              class="d-inline"
              style="font-size: 11px;"
              v-if="!scope.row.isInit"
            >
              <template v-if="scope.row.progressWriting >= 0">
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressWriting }}</i
                >
              </template>
              <template v-else>
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{ scope.row.progressWriting | abs }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Total" width="100%" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub">
              <span>{{ scope.row.total_score }}</span>
            </div>
            <div
              class="d-inline"
              style="font-size: 11px;"
              v-if="!scope.row.isInit"
            >
              <template v-if="scope.row.progressTotal >= 0">
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressTotal }}</i
                >
              </template>
              <template v-else>
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{ scope.row.progressTotal | abs }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Super Score" align="center">
        <div slot-scope="scope">
          <!-- super score Total -->
          <el-tooltip
            content="Total"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              style="display: flex; align-items: center; justify-content: center;"
            >
              <div class="d-inline superScoreTotal">
                <span>{{ scope.row.superTotal }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="!scope.row.isInit"
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{ scope.row.progressSuperTotal }}</i
                >
              </div>
            </div>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import ToeflMyBestScore from "@/components/userProfile/ToeflMyBestScore";
import _ from "lodash";
import { instant } from "@ivy-way/material";

export default {
  props: ["role"],
  components: {
    ToeflMyBestScore
  },
  data() {
    return {
      toeflData: [],
      maxScore: {
        reading: 0,
        readingDate: "",
        listening: 0,
        listeningDate: "",
        speaking: 0,
        speakingDate: "",
        writing: 0,
        writingDate: ""
      }
    };
  },
  computed: {
    ...mapGetters("profile", [
      "getUserData",
      "getUserDataByAdmin"
    ]),
    instant() {
      return instant;
    }
  },
  created() {
    let currentData = {};
    if (this.role === "student") currentData = this.getUserData;
    else if (this.role === "parent") currentData = this.getUserDataByAdmin;
    else if (this.role === "admin") currentData = this.getUserDataByAdmin;
    // toeflData
    if (currentData.student.toefl_results.length > 0) {
      this.toeflData = _.cloneDeep(currentData.student.toefl_results);
      const toeflLen = this.toeflData.length;
      let maxTotal = this.toeflData[toeflLen - 1].total_score;
      this.toeflData[toeflLen - 1].superTotal = maxTotal;
      this.toeflData[toeflLen - 1].isInit = true;

      // assign data to maxScore
      this.maxScore.reading =
        currentData.student.toefl_results[toeflLen - 1].reading_score;
      this.maxScore.listening =
        currentData.student.toefl_results[toeflLen - 1].listening_score;
      this.maxScore.speaking =
        currentData.student.toefl_results[toeflLen - 1].speaking_score;
      this.maxScore.writing =
        currentData.student.toefl_results[toeflLen - 1].writing_score;
      // assign date
      this.maxScore.readingDate =
        currentData.student.toefl_results[toeflLen - 1].date_taken;
      this.maxScore.listeningDate =
        currentData.student.toefl_results[toeflLen - 1].date_taken;
      this.maxScore.speakingDate =
        currentData.student.toefl_results[toeflLen - 1].date_taken;
      this.maxScore.writingDate =
        currentData.student.toefl_results[toeflLen - 1].date_taken;

      for (let i = toeflLen - 2; i >= 0; i--) {
        this.toeflData[i].progressReading =
          this.toeflData[i].reading_score - this.toeflData[i + 1].reading_score;
        this.toeflData[i].progressListening =
          this.toeflData[i].listening_score -
          this.toeflData[i + 1].listening_score;
        this.toeflData[i].progressSpeaking =
          this.toeflData[i].speaking_score -
          this.toeflData[i + 1].speaking_score;
        this.toeflData[i].progressWriting =
          this.toeflData[i].writing_score - this.toeflData[i + 1].writing_score;
        this.toeflData[i].progressTotal =
          this.toeflData[i].total_score - this.toeflData[i + 1].total_score;

        // cal max data to maxScore
        if (
          currentData.student.toefl_results[i].reading_score >
          this.maxScore.reading
        ) {
          this.maxScore.reading =
            currentData.student.toefl_results[i].reading_score;
          this.maxScore.readingDate =
            currentData.student.toefl_results[i].date_taken;
        }
        if (
          currentData.student.toefl_results[i].listening_score >
          this.maxScore.listening
        ) {
          this.maxScore.listening =
            currentData.student.toefl_results[i].listening_score;
          this.maxScore.listeningDate =
            currentData.student.toefl_results[i].date_taken;
        }
        if (
          currentData.student.toefl_results[i].speaking_score >
          this.maxScore.speaking
        ) {
          this.maxScore.speaking =
            currentData.student.toefl_results[i].speaking_score;
          this.maxScore.speakingDate =
            currentData.student.toefl_results[i].date_taken;
        }
        if (
          currentData.student.toefl_results[i].writing_score >
          this.maxScore.writing
        ) {
          this.maxScore.writing =
            currentData.student.toefl_results[i].writing_score;
          this.maxScore.writingDate =
            currentData.student.toefl_results[i].date_taken;
        }
        this.maxScore.speaking = Math.max(
          currentData.student.toefl_results[i].speaking_score,
          this.maxScore.speaking
        );
        this.maxScore.writing = Math.max(
          currentData.student.toefl_results[i].writing_score,
          this.maxScore.writing
        );
        // super total
        this.toeflData[i].superTotal =
          this.maxScore.reading +
          this.maxScore.listening +
          this.maxScore.speaking +
          this.maxScore.writing;

        this.toeflData[i].progressSuperTotal =
          this.toeflData[i].superTotal - this.toeflData[i + 1].superTotal;
      }
    }
  },
  mounted() {},
  methods: {
    toTranscript(id) {
      this.routerPush({
        name: "Transcript",
        query: {
          id: id
        }
      });
    }
  },
  filters: {
    abs: function(value) {
      return Math.abs(value);
    }
  }
};
</script>

<style scoped>
.titleCell {
  cursor: pointer;
}
.titleCell:hover {
  color: #42a16a;
  text-decoration: underline;
}
.generalSub {
  font-size: 15px;
  font-weight: bold;
}
.superScoreItem {
  font-size: 15px;
  font-weight: bold;
}
.superScoreTotal {
  font-size: 18px;
  font-weight: bold;
}
</style>
