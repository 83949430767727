<template>
  <div>
    <h5>用戶角色</h5>
    <hr />
    <div class="ml-4">
      <el-form ref="form" label-position="left" label-width="30px">
        <el-form-item>
          <div slot="label"><i class="fas fa-user-tie"></i></div>

          <el-tooltip
            content="可以進行所有管理操作，但不可以看到金額。"
            placement="right"
            :visible-arrow="false"
          >
            <el-checkbox
              v-model="isStaff"
              :true-label="1"
              :false-label="0"
              @change="togggleRole('staff')"
            >
              <span class="mr-1">行政人員</span>
              <i class="fas fa-question-circle" />
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <div slot="label"><i class="fas fa-user-edit"></i></div>
          <el-tooltip content="可以在系統中錄入題目。" placement="right" :visible-arrow="false">
            <el-checkbox
              v-model="isTestEditor"
              :true-label="1"
              :false-label="0"
              @change="togggleRole('test-editor')"
            >
              <span class="mr-1">考試編輯者</span>
              <i class="fas fa-question-circle" />
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <div slot="label"><i class="fas fa-chalkboard-teacher"></i></div>
          <el-checkbox
            v-model="isTeacher"
            :true-label="1"
            :false-label="0"
            @change="togggleRole('teacher')"
          >
            老師
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <div slot="label"><i class="fas fa-user-shield"></i></div>
          <el-tooltip
            content="鏈接學生，可以查看學生的所有信息。"
            placement="right"
            :visible-arrow="false"
          >
            <el-checkbox
              v-model="isParent"
              :true-label="1"
              :false-label="0"
              @change="togggleRole('parent')"
            >
              <span class="mr-1">家長</span>
              <i class="fas fa-question-circle" />
            </el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <div slot="label"><i class="fas fa-user"></i></div>
          <el-checkbox
            v-model="isStudent"
            :true-label="1"
            :false-label="0"
            @change="togggleRole('student')"
          >
            學生</el-checkbox
          >
        </el-form-item>
      </el-form>
    </div>
    <h5>用戶狀態</h5>
    <hr />
    <div class="ml-4">
      <el-radio-group v-model="isActive" @change="togggleActive">
        <el-radio-button :label="true">Active</el-radio-button>
        <el-radio-button :label="false">Inactive</el-radio-button>
      </el-radio-group>
    </div>
    <!-- <ol>
      <li class="role">
        <span @click="togggleRole('staff')">
          {{
            $t(
              `userProfile.setting.${
                isStaff() ? "deleteStaffRole" : "addStaffRole"
              }`
            )
          }}
        </span>
      </li>
      <li class="role">
        <span @click="togggleRole('student')">
          {{
            $t(
              `userProfile.setting.${
                isStudent() ? "deleteStudentRole" : "addStudentRole"
              }`
            )
          }}
        </span>
      </li>
      <li class="role">
        <span @click="togggleRole('teacher')">
          {{
            $t(
              `userProfile.setting.${
                isTeacher() ? "deleteTeacherRole" : "addTeacherRole"
              }`
            )
          }}
        </span>
      </li>
      <li class="role">
        <span @click="togggleRole('parent')">
          {{
            $t(
              `userProfile.setting.${
                isParent() ? "deleteParentRole" : "addParentRole"
              }`
            )
          }}
        </span>
      </li>
      <li class="role">
        <span @click="togggleRole('test-editor')">
          {{ isTestEditor() ? "移除考試編輯者角色" : "增加考試編輯者角色" }}
        </span>
      </li>
      <li class="role">
        <span @click="togggleActive()">
          {{ $t(`userProfile.setting.${isActive ? "inactive" : "active"}`) }}
        </span>
      </li>
    </ol> -->
  </div>
</template>

<script>
import userApi from "@/apis/user";
import usersApi from "@/apis/users";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    roles: {
      type: Array,
      default: () => []
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isStudent: 0,
      isTeacher: 0,
      isStaff: 0,
      isParent: 0,
      isTestEditor: 0
    };
  },
  watch: {
    roles() {
      this.setRoles();
    }
  },
  computed: {
    // isStudent() {
    //   return this.roles.includes("student");
    // },
    // isTeacher() {
    //   return this.roles.includes("teacher");
    // },
    // isStaff() {
    //   return this.roles.includes("staff");
    // },
    // isParent() {
    //   return this.roles.includes("parent");
    // },
    // isTestEditor() {
    //   return this.roles.includes("test-editor");
    // }
  },
  mounted() {
    this.setRoles();
  },
  methods: {
    setRoles() {
      if (this.roles.includes("student")) {
        this.isStudent = 1;
      }
      if (this.roles.includes("teacher")) {
        this.isTeacher = 1;
      }
      if (this.roles.includes("staff")) {
        this.isStaff = 1;
      }
      if (this.roles.includes("parent")) {
        this.isParent = 1;
      }
      if (this.roles.includes("test-editor")) {
        this.isTestEditor = 1;
      }
    },
    // isStudent() {
    //   return this.roles.includes("student");
    // },
    // isTeacher() {
    //   return this.roles.includes("teacher");
    // },
    // isStaff() {
    //   return this.roles.includes("staff");
    // },
    // isParent() {
    //   return this.roles.includes("parent");
    // },
    // isTestEditor() {
    //   return this.roles.includes("test-editor");
    // },
    async togggleRole(role) {
      console.log(role);
      console.log(this.isStudent);
      if (role === "student") {
        await this.togggleStudentRole();
      }
      if (role === "staff") {
        await this.togggleStaffRole();
      } else if (role === "teacher") {
        await this.togggleTeacherRole();
      } else if (role === "test-editor") {
        await this.togggleTestEditorRole();
      } else if (role === "parent") {
        const childs =
          this.$store.getters["profile/getUserDataByAdmin"].parent
            ?.son_daughter || [];
        if (childs.length > 0) {
          try {
            await this.$confirm(
              "解除這個使用者會一起移除目前綁定的學生關係，確定要移除嗎？",
              this.$t("message.notice"),
              {
                confirmButtonText: this.$t("message.continue"),
                cancelButtonText: this.$t("message.cancel"),
                type: "warning"
              }
            );
          } catch (e) {
            return (this.isParent = 1);
          }
          await userApi.disconnectStudentAndParent(
            this.id,
            childs.map(({ student_user_id }) => student_user_id)
          );
          await this.togggleParentRole();
        } else {
          await this.togggleParentRole();
        }
      }
      this.$emit("initialProfile");
    },
    async togggleActive() {
      await usersApi.toggleUserActive(this.id);
      this.$emit("initialProfile");
    },
    async togggleStudentRole() {
      await usersApi.togggleStudentRole(this.id, Number(this.isStudent));
    },
    async togggleStaffRole() {
      await usersApi.togggleStaffRole(this.id, Number(this.isStaff));
    },
    async togggleTeacherRole() {
      await usersApi.togggleTeacherRole(this.id, Number(this.isTeacher));
    },
    async togggleTestEditorRole() {
      await usersApi.togggleTestEditorRole(this.id, Number(this.isTestEditor));
    },
    async togggleParentRole() {
      await usersApi.togggleParentRole(this.id, Number(this.isParent));
    }
  }
};
</script>

<style scoped>
.role {
  cursor: pointer;
  color: #43a06b;
}
</style>
