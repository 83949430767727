<template>
  <div class="pl-2 mt-3">
    <h4 style="color:#42a16a">ACT Tests:</h4>
    <ActMyBestScore v-if="bestScore !== ''" :bestScore="bestScore" />
    <el-table :data="sat_results" style="margin-top: 40px" border>
      <el-table-column label="Date taken" width="180" align="center">
        <div slot-scope="scope">
          <a :href="'/act/result?user_exam_id=' + scope.row.user_exam_id">
            {{ instant.formatGoogleTime(scope.row.date_taken) }}
          </a>
        </div>
      </el-table-column>
      <el-table-column
        prop="full_name"
        label="Test name"
        align="center"
      ></el-table-column>
      <el-table-column label="English" width="180" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub superScoreTotal">
              <span>{{ scope.row.english_score }} &nbsp;</span>
            </div>
            <div class="d-inline" style="font-size: 11px;">
              <template
                v-if="
                  getProgress(
                    scope.row.section_english_score,
                    scope.$index,
                    'section_english_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.section_english_score,
                        scope.$index,
                        "section_english_score"
                      )
                    )
                  }}</i
                >
              </template>
              <template
                v-if="
                  getProgress(
                    scope.row.section_english_score,
                    scope.$index,
                    'section_english_score'
                  ) < 0
                "
              >
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.section_english_score,
                        scope.$index,
                        "section_english_score"
                      )
                    )
                  }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Math" width="180" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub superScoreTotal">
              <span>{{ scope.row.math_score }} &nbsp;</span>
            </div>
            <div class="d-inline" style="font-size: 11px;">
              <template
                v-if="
                  getProgress(
                    scope.row.math_score,
                    scope.$index,
                    'math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.math_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
              <template
                v-if="
                  getProgress(
                    scope.row.math_score,
                    scope.$index,
                    'math_score'
                  ) < 0
                "
              >
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.math_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Reading" width="180" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub superScoreTotal">
              <span>{{ scope.row.reading_score }} &nbsp;</span>
            </div>
            <div class="d-inline" style="font-size: 11px;">
              <template
                v-if="
                  getProgress(
                    scope.row.reading_score,
                    scope.$index,
                    'math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.reading_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
              <template
                v-if="
                  getProgress(
                    scope.row.reading_score,
                    scope.$index,
                    'math_score'
                  ) < 0
                "
              >
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.reading_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Science" width="180" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub superScoreTotal">
              <span>{{ scope.row.science_score }} &nbsp;</span>
            </div>
            <div class="d-inline" style="font-size: 11px;">
              <template
                v-if="
                  getProgress(
                    scope.row.science_score,
                    scope.$index,
                    'math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.science_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
              <template
                v-if="
                  getProgress(
                    scope.row.science_score,
                    scope.$index,
                    'math_score'
                  ) < 0
                "
              >
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.science_score,
                        scope.$index,
                        "math_score"
                      )
                    )
                  }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>   
      <el-table-column label="Total" width="180" align="center">
        <div slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div class="d-inline generalSub superScoreTotal">
              <span>{{ scope.row.total_score }} &nbsp;</span>
            </div>
            <div class="d-inline" style="font-size: 11px;">
              <template
                v-if="
                  getProgress(
                    scope.row.total_score,
                    scope.$index,
                    'total_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.total_score,
                        scope.$index,
                        "total_score"
                      )
                    )
                  }}</i
                >
              </template>
              <template
                v-if="
                  getProgress(
                    scope.row.total_score,
                    scope.$index,
                    'total_score'
                  ) < 0
                "
              >
                <i
                  style="font-weight:700; color:#E53935"
                  class="el-icon-caret-bottom"
                  >{{
                    Math.abs(
                      getProgress(
                        scope.row.total_score,
                        scope.$index,
                        "total_score"
                      )
                    )
                  }}</i
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="Super Score" width="300" align="center">
        <div slot-scope="scope">
          <!-- super score English -->
          <el-tooltip
            content="English"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              class="d-inline-block pl-2"
              style="width:50%; text-align:center"
            >
              <div class="d-inline superScoreItem">
                <span>{{ scope.row.max_english_score }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="
                  getProgress(
                    scope.row.max_english_score,
                    scope.$index,
                    'max_english_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    getProgress(
                      scope.row.max_english_score,
                      scope.$index,
                      "max_english_score"
                    )
                  }}</i
                >
              </div>
            </div>
          </el-tooltip>
          <!-- super score Math -->
          <el-tooltip
            content="Math"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              class="d-inline-block pl-1"
              style="width:50%; text-align:center"
            >
              <div class="d-inline superScoreItem">
                <span>{{ scope.row.max_math_score }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="
                  getProgress(
                    scope.row.max_math_score,
                    scope.$index,
                    'max_math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    getProgress(
                      scope.row.max_math_score,
                      scope.$index,
                      "max_math_score"
                    )
                  }}</i
                >
              </div>
            </div>
          </el-tooltip>
           <!-- super score Reading -->
          <el-tooltip
            content="Reading"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              class="d-inline-block pl-1"
              style="width:50%; text-align:center"
            >
              <div class="d-inline superScoreItem">
                <span>{{ scope.row.max_reading_score }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="
                  getProgress(
                    scope.row.max_reading_score,
                    scope.$index,
                    'max_math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    getProgress(
                      scope.row.max_reading_score,
                      scope.$index,
                      "max_reading_score"
                    )
                  }}</i
                >
              </div>
            </div>
          </el-tooltip>
           <!-- super score Math -->
          <el-tooltip
            content="Science"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              class="d-inline-block pl-1"
              style="width:50%; text-align:center"
            >
              <div class="d-inline superScoreItem">
                <span>{{ scope.row.max_science_score }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="
                  getProgress(
                    scope.row.max_science_score,
                    scope.$index,
                    'max_math_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    getProgress(
                      scope.row.max_science_score,
                      scope.$index,
                      "max_science_score"
                    )
                  }}</i
                >
              </div>
            </div>
          </el-tooltip>
          <el-divider class="mt-1 mb-1"></el-divider>
          <!-- super score Total -->
          <el-tooltip
            content="Total"
            placement="top"
            style="cursor:pointer;"
            popper-class="tooltipColor"
          >
            <div
              style="display: flex; align-items: center; justify-content: center;"
            >
              <div class="d-inline superScoreTotal">
                <span>{{ scope.row.max_total_score }}</span>
              </div>
              <div
                class="d-inline"
                style="font-size: 12px;"
                v-if="
                  getProgress(
                    scope.row.max_total_score,
                    scope.$index,
                    'max_total_score'
                  ) > 0
                "
              >
                <i
                  style="font-weight:700; color:#689F38"
                  class="el-icon-caret-top"
                  >{{
                    getProgress(
                      scope.row.max_total_score,
                      scope.$index,
                      "max_total_score"
                    )
                  }}</i
                >
              </div>
            </div>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ActMyBestScore from "@/components/userProfile/ActMyBestScore";
import { instant } from "@ivy-way/material";

export default {
  props: ["sat_results"],
  components: {
    ActMyBestScore
  },
  computed: {
    instant() {
      return instant;
    }
  },
  data() {
    return {
      bestScore: ""
    };
  },
  watch: {
    sat_results() {
      this.getBestScore();
    }
  },
  mounted() {
    this.getBestScore();
  },
  methods: {
    getProgress(score, index, section) {
      let progress = 0;
      if (index < this.sat_results.length - 1) {
        progress = score - this.sat_results[index + 1][section];
      }
      // console.log(progress);
      return progress;
    },
    getBestScore() {
      let bestScore = {
        total_score: 0,
        section_english_score: 0,
        math_score: 0
      };
      if (this.sat_results.length > 0) {
        bestScore.total_score = this.sat_results[0].max_total_score;
        bestScore.section_english_score = this.sat_results[0].max_english_score;
        bestScore.section_math_score = this.sat_results[0].max_math_score;
        bestScore.section_reading_score = this.sat_results[0].max_reading_score;
        bestScore.section_science_score = this.sat_results[0].max_science_score;
      }
      this.bestScore = bestScore;
    }
  },
  filters: {
    abs: function(value) {
      return Math.abs(value);
    }
  }
};
</script>

<style scoped>
.generalSub {
  font-size: 15px;
  font-weight: bold;
}
.superScoreItem {
  font-size: 15px;
  font-weight: bold;
}
.superScoreTotal {
  font-size: 18px;
  font-weight: bold;
}
</style>
